var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "smd-custom-select",
      on: {
        blur: function ($event) {
          _vm.open = false
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchQuery,
            expression: "searchQuery",
          },
        ],
        ref: "search",
        staticClass: "smd-selected",
        class: { open: _vm.open },
        attrs: { type: "text", placeholder: "Select" },
        domProps: { value: _vm.searchQuery },
        on: {
          focus: function ($event) {
            _vm.open = true
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchQuery = $event.target.value
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "smd-items",
          class: { selectHide: !_vm.open },
          attrs: { "stay-open": _vm.stayOpen },
        },
        [
          _vm.searchOptions.length > 2
            ? _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.selectAll()
                    },
                  },
                },
                [
                  !_vm.stayOpen
                    ? _c("input", {
                        attrs: { type: "checkbox" },
                        domProps: { checked: _vm.allIsSelected },
                      })
                    : _vm._e(),
                  _c("span", [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.allIsSelected || _vm.isSelected
                            ? "Remove"
                            : "Select"
                        ) + " All"
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.searchOptions, function (option) {
            return _c(
              "div",
              {
                key: option.id,
                on: {
                  click: function ($event) {
                    return _vm.selectOption(option)
                  },
                },
              },
              [
                !_vm.stayOpen
                  ? _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked:
                          _vm.selected.filter(function (record) {
                            return record.id === option.id
                          }).length > 0,
                      },
                    })
                  : _vm._e(),
                _c("span", { domProps: { innerHTML: _vm._s(option.label) } }),
              ]
            )
          }),
          _vm.searchOptions.length === 0
            ? _c("div", [_c("span", [_vm._v("No results found")])])
            : _vm._e(),
        ],
        2
      ),
      !_vm.stayOpen
        ? _c(
            "div",
            {
              staticClass: "smd-close",
              class: { selectHide: !_vm.open },
              on: {
                click: function ($event) {
                  _vm.open = false
                },
              },
            },
            [_c("center", [_vm._v("Close")])],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }